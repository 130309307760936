.SplashScreen {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
  background-color: rgba(226, 33, 28, 255);
}

.verticallyCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
